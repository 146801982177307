<template>
  <div id="main">
    <banner
      title="Paket retur belum diterima atau rusak? kamu bisa komplain sesuai batas pengajuan komplain berikut :"
      :message="['Sicepat, Idexpress, SAP & J&T: 2 hari', 'JNE & Ninja: 7 hari']"
    />
    <banner
      title="Paket kamu stuck? kamu bisa ajukan klaim stuck sesuai batas pengajuan klaim berikut :"
      :message="['Idexpress & Ninja: 15 - 30 hari*', 'JNE, Sicepat, SAP & J&T: 15 - 60 hari*']"
    />
    <b-row class="mb-1 justify-content-between align-items-center">
      <b-col cols="6">
        <div
          class="py-2 px-50"
          style="border: 1px solid #626262; border-radius: 9px;"
        >
          <b-row class="mb-2 align-items-center">
            <b-col cols="5">
              <span class="text-black">
                <strong>
                  Jumlah Retur
                </strong>
              </span>
            </b-col>
            <b-col cols="7">
              <div class="d-flex align-items-center">
                <span class="text-black">
                  <strong>
                    : {{ totalOrderRetur }}/{{ totalOrder }}({{ percentageRetur }}%)
                  </strong>
                </span>
                <b-dropdown
                  :text="filterMetricLabel"
                  variant="outline-dark"
                  class="mx-50"
                  size="sm"
                >
                  <b-dropdown-item @click="metricRetur('Bulan ini')">
                    Bulan ini
                  </b-dropdown-item>
                  <b-dropdown-item @click="metricRetur('Bulan lalu')">
                    Bulan lalu
                  </b-dropdown-item>
                </b-dropdown>
                <img
                  id="infoTrackingMonth"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                >
                <b-popover
                  triggers="hover"
                  target="infoTrackingMonth"
                  placement="top"
                  variant="dark"
                >
                  Jumlah retur di bulan tersebut dibandingkan dengan total jumlah paket yang ordernya DIBUAT di bulan tersebut. Jika filter ke bulan lalu, bisa jadi returan masih bisa berubah, karena ada paket yang masih dalam perjalanan belum sampai ke customer s.d awal bulan ini
                </b-popover>
              </div>
            </b-col>
          </b-row>
          <b-row class="align-items-center">
            <b-col cols="5">
              <span class="text-black">
                <strong>
                  Paket dalam Perjalanan
                </strong>
              </span>
            </b-col>
            <b-col cols="7">
              <div class="d-flex align-items-center">
                <span class="text-black">
                  <strong>
                    : {{ returOnProcess }}
                  </strong>
                </span>
                <div
                  :style="isRetur ? 'background: #E31A1A; border-radius: 12px;' : 'border: 1px solid #E31A1A; border-radius: 12px;'"
                  :class="isRetur ? 'px-50 mx-50 cursor-pointer' : 'px-50 mx-50 cursor-pointer'"
                  @click="fetchIsRetur"
                >
                  <span :class="isRetur ? 'text-white' : 'text-primary'">
                    Masih dijalan
                  </span>
                </div>
                <img
                  id="infoTracking"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                >
                <b-popover
                  triggers="hover"
                  target="infoTracking"
                  placement="top"
                  class="bg-dark"
                  variant="dark"
                >Perhatikan yaa, ketika retur sudah terdeteksi sampai di gudang, kamu punya waktu 10 hari jika barang tidak ditemukan, kamu bisa komplain ke ekspedisi untuk klaim</b-popover>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col
        md="5"
        class="d-flex"
      >
        <b-form-input
          v-model="formSearch"
          type="search"
          class="form-search mr-2"
          placeholder="Cari Pelanggan atau Resi"
          @input="fetchData(formSearch)"
        />
        <b-icon-search class="icon-search" />
        <div style="position: relative;">
          <img
            id="buttonFilter"
            src="https://storage.googleapis.com/komerce/assets/svg/filter-icon-orange.svg"
            class="cursor-pointer"
          >
          <b-badge
            variant="primary"
            style="position: absolute; border-radius: 1.358rem; top: -15%; right: 0%;"
          >
            {{ totalFilterDataOrder }}
          </b-badge>
        </div>
        <b-popover
          id="popoverFilter"
          target="buttonFilter"
          triggers="click"
          placement="bottomleft"
        >
          <label>Tanggal</label>
          <b-row>
            <b-col md="6">
              <flat-pickr
                v-model="startDate"
                class="form-control"
                placeholder="Mulai Dari"
                :config="{ mode: 'single', altInput: true, altFormat: 'j/n/Y', dateFormat: 'Y-m-d',}"
                @input="setFilterDate"
              />
            </b-col>
            <b-col md="6">
              <flat-pickr
                v-model="endDate"
                class="form-control"
                placeholder="Sampai Dengan"
                :config="{ mode: 'single', altInput: true, altFormat: 'j/n/Y', dateFormat: 'Y-m-d', minDate: startDate}"
                @input="setFilterDate"
              />
            </b-col>
          </b-row>
          <label class="mt-1">Gudang</label>
          <v-select
            v-model="addressId"
            :options="filterWarehouses"
            :reduce="(option) => option"
            label="name"
            @input="setFilterAddress"
          >
            <template
              slot="option"
              slot-scope="option"
            >
              <img
                v-if="option.is_kompack === 1"
                src="https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg"
                class="mr-[5px] w-[14px] h-[14px] d-inline mb-[3px]"
              >
              {{ option.name }} {{ option.unsubscribe === 1 ? '(Unsubscribe)' : '' }}
            </template>
            <span
              slot="no-options"
              @click="$refs.select.open = false"
            />
          </v-select>
          <label class="mt-1">Produk</label>
          <v-select
            v-model="productName"
            :options="filterProducts"
            :reduce="(option) => option.id"
            label="product_name"
            @input="setFilterProduct"
          >
            <span
              slot="no-options"
              @click="$refs.select.open = false"
            />
          </v-select>
          <label class="mt-1">Metode Pembayaran</label>
          <v-select
            v-model="paymentMethod"
            :options="['COD', 'BANK TRANSFER']"
            @input="setFilterPayment"
          />
          <b-row class="mx-auto mt-2">
            <b-button
              variant="outline-primary"
              class="mr-1"
              @click.prevent="resetFilter()"
            >
              Reset
            </b-button>
            <b-button
              variant="primary"
              @click.prevent="fetchData()"
            >
              Terapkan
            </b-button>
          </b-row>
        </b-popover>
      </b-col>
    </b-row>
    <b-table
      id="table-order"
      responsive
      show-empty
      empty-text="Tidak ada data untuk ditampilkan."
      :items="items"
      :fields="fields"
      :busy="loadingTable"
    >
      <template #cell(order_date)="data">
        <div
          style="min-width:150px!important;"
        >
          <p class="font-bold text-[14px] text-[#222222] mb-0">
            {{ DAY_MONTH_YEAR(data.item.order_date) }}
          </p>
          <p class="font-semibold text-[12px] text-[#828282] mb-0">
            {{ TIME(data.item.order_date) }}
          </p>
          <span class="d-flex items-center text-secondary font-normal">
            <img
              v-if="data.item.is_kompack === 0"
              src="@/assets/images/icons/warehouse.svg"
              class="mr-[5px]"
            >
            <img
              v-if="data.item.is_kompack === 1"
              src="https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg"
              class="mr-[5px] w-[14px] h-[14px]"
            >
            {{ data.item.warehouse_name }}
          </span>
        </div>
      </template>
      <template #cell(customer_name)="data">
        <span class="font-bold">{{ data.item.customer_name }}</span><br>
        <div
          v-if="data.item.is_komship === 1 || data.item.is_kompack === 1"
          class="d-flex items-center gap-2 mt-[4px]"
        >
          <img
            :src="data.item.shipment_image_path"
            class="w-14"
          ><span>{{ data.item.shipment_alias }}</span>
        </div>
        <div
          v-if="data.item.is_crossborder === 1"
          class="mt-1 bg-[#E2E2E2] p-[5px] rounded d-flex text-sm text-black gap-2 w-[160px]"
        >
          <span>Antar Negara</span>
          <span class="d-flex">
            ({{ data.item.currency === 'SGD' ? 'SG' : 'MY' }}
            <div class="ml-[5px] align-content-center">
              <img
                class="w-[15px]"
                :src="data.item.currency === 'SGD' ? 'https://flagcdn.com/w320/sg.png' : 'https://flagcdn.com/w320/my.png'"
                alt="flag"
              >
            </div>)
          </span>
        </div>
      </template>
      <template #cell(product)="data">
        <div v-if="data.item.product[0]">
          <div
            v-for="(itemProduct, index) in data.item.product.slice(0, 1)"
            :key="index+1"
            class="d-flex"
            style="min-width:160px!important"
          >
            <img
              v-if="data.item.product[0].product_image === null || data.item.product[0].product_image === ''"
              class="image-product"
              src="https://storage.googleapis.com/komerce/assets/avatars/image-null.png"
            >
            <img
              v-else
              class="image-product"
              :src="data.item.product[0].product_image"
              :alt="data.item.product[0].product_image"
            >
            <div>
              <div style="margin-left:5px;">
                <span class="d-flex font-bold">{{ data.item.product[0].product_name }}</span>
                <span
                  v-if="itemProduct.variant_name !== '0'"
                  class="text-primary"
                >{{ itemProduct.variant_name }}</span>
              </div>
              <div
                v-if="itemProduct.is_bundling === 1"
                class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer mt-[5px] w-[7rem]"
                @click="$bvModal.show(`bundling-${String(data.index)}-${String(itemProduct.detail_id)}`)"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                  alt="bundling"
                  width="16"
                >
                <div>Bundling</div>
              </div>
            </div>
            <div
              class="font-bold ml-auto"
            >
              x{{ itemProduct.qty }}
            </div>
            <modal-bundling-quantity
              v-if="itemProduct.is_bundling === 1"
              :data="itemProduct"
              :index="data.index"
            />
          </div>
          <div v-if="data.item.product.length > 1">
            <b-collapse :id="'collapse-'+data.item.order_id">
              <div
                v-for="item in data.item.product.slice(1, data.item.product.length)"
                :key="item.order_id"
                class="d-flex mt-1"
                style="min-width:160px!important"
              >
                <img
                  v-if="item.product_image === null || item.product_image === ''"
                  class="image-product"
                  src="https://storage.googleapis.com/komerce/assets/avatars/image-null.png"
                >
                <img
                  v-else
                  class="image-product"
                  :src="item.product_image"
                  :alt="item.product_image"
                >
                <div>
                  <div style="margin-left:5px;">
                    <span class="d-flex font-bold">{{ item.product_name }}</span>
                    <span
                      v-if="item.variant_name !== '0'"
                      class="text-primary"
                    >{{ item.variant_name }}</span>
                  </div>
                  <div
                    v-if="item.is_bundling === 1"
                    class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer mt-[5px] w-[7rem]"
                    @click="$bvModal.show(`bundling-${String(data.index)}-${String(item.detail_id)}`)"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                      alt="bundling"
                      width="16"
                    >
                    <div>Bundling</div>
                  </div>
                </div>
                <div
                  class="font-bold ml-auto"
                >
                  x{{ item.qty }}
                </div>
                <modal-bundling-quantity
                  v-if="item.is_bundling === 1"
                  :data="item"
                  :index="data.index"
                />
              </div>
            </b-collapse>
          </div>
        </div>
      </template>
      <template #cell(order_status)="data">
        <b-badge
          v-if="data.item.is_retur === 1"
          class="text-danger border-danger"
          style="background-color:#FCBEBE"
          pill
        >
          Perjalanan Pulang
        </b-badge>
        <b-badge
          v-if="data.item.is_retur === 2"
          class="text-success border-success"
          style="background-color:#BEFCDE"
          pill
        >
          Retur Diterima
        </b-badge>
        <b-badge
          v-if="data.item.is_retur === 3"
          class="text-warning border-warning"
          style="background-color:#FFF2E2"
          pill
        >
          Retur Luar Negeri
        </b-badge>
        <span v-if="data.item.is_retur !== 1 && data.item.is_retur !== 2 && data.item.is_retur !== 3">-</span>
      </template>
      <template #cell(grand_total)="data">
        <span class="d-flex">
          {{ IDR(data.item.grand_total) }}
          <img
            v-if="data.item.order_notes !== '0' && data.item.order_notes !== '' && data.item.order_notes !== null"
            :id="`infoNote` + data.item.order_id"
            src="https://storage.googleapis.com/komerce/assets/icons/info-order-notes.svg"
            class="ml-auto cursor-pointer"
            style="max-width:20px"
          >
          <b-popover
            triggers="hover"
            :target="`infoNote` + data.item.order_id"
            placement="bottomright"
          >
            {{ data.item.order_notes }}
          </b-popover>
        </span>
        <span
          v-if="data.item.payment_method === 'COD'"
          class="text-primary"
        >
          COD
        </span>
        <div
          v-else-if="data.item.payment_method === 'BANK TRANSFER'"
          class="d-flex"
        >
          <span class="text-primary">Transfer</span>
          <img
            v-if="data.item.bank !== '0'"
            :id="`iconInfo` + data.item.order_id"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            class="icon-info"
          >
          <b-popover
            triggers="hover"
            :target="`iconInfo` + data.item.order_id"
            placement="bottomleft"
          >
            <label>Nama Bank:</label><br>
            <span class="font-bold">{{ data.item.bank }}</span><br>
            <label>No Rekening:</label><br>
            <span class="font-bold">{{ data.item.bank_account_no }}</span><br>
            <label>Pemilik Rekening:</label><br>
            <span class="font-bold">{{ data.item.bank_account_name }}</span><br>
          </b-popover>
        </div>
      </template>
      <template #cell(retur_date)="data">
        <span v-if="data.item.is_retur !== 1">{{ DASH_DATE(data.item.updated_at) }} {{ TIME(data.item.updated_at) }}</span>
        <span v-else>-</span>
      </template>
      <template #cell(details)="data">
        <div class="d-flex flex-column justify-content-center text-">
          <div
            class="d-flex flex-wrap align-items-center mb-1"
            style="width: max-content;"
          >
            <b-button
              variant="none"
              class="button-detail d-flex"
              :to="{ name: $route.meta.routeDetail, params: { order_id: data.item.order_id } }"
            >
              Lihat Detail
            </b-button>
            <b-button
              v-if="(!data.item.claim_data.some(item => item.claim_type === 'rts_tidak_diterima' || item.claim_type === 'rts_rusak' || (item.claim_type === 'paksa_rts' && item.claim_status === 1)) && !maxClaim(data) && data.item.is_retur === 2) && data.item.is_crossborder !== 1"
              variant="flat-dark"
              class="btn-icon p-0"
              @click="toggleProblemPackage(data.item)"
            >
              <b-img
                class="rotate-90"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/more.svg"
              />
            </b-button>
            <b-button
              v-if="data.item.force_claim_available && data.item.is_retur === 1 && data.item.is_crossborder !== 1"
              variant="flat-dark"
              class="btn-icon p-0"
            >
              <b-img
                :id="`hover-force-retur-${data.index}`"
                class="rotate-90"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/more.svg"
              />
              <b-popover
                :target="`hover-force-retur-${data.index}`"
                triggers="hover"
                placement="top"
                :custom-class="isActive ? 'bg-primary text-white' : ''"
              >
                <div
                  type="button"
                  class="d-flex w-100"
                  @mouseenter="isActive = true"
                  @mouseleave="isActive = false"
                  @click="forceDelivered(data.item.order_id)"
                >
                  <span :class="isActive ? 'text-white' : ''">Laporan paket retur sudah diterima</span>
                  <b-img
                    :id="`tooltip-force-${data.index}`"
                    class="ml-[5px]"
                    :src="isActive ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/message-question.svg' : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/message-question.svg'"
                  />
                  <b-tooltip
                    :target="`tooltip-force-${data.index}`"
                    triggrers="hover"
                    variant="light"
                  >
                    <span>Laporkan jika Paket Retur kamu sebenarnya sudah Diterima namun status masih Perjalanan Pulang</span>
                  </b-tooltip>
                </div>
              </b-popover>
            </b-button>
          </div>
          <div
            v-if="data.item.claim_data.some(item => ((item.claim_type === 'rts_tidak_diterima' || item.claim_type === 'rts_rusak') && data.item.is_retur === 2) || (item.claim_type === 'paksa_rts' && data.item.is_retur === 1))"
            :id="`status-claim-tooltip-${data.index}`"
            class="px-2"
          >
            <div
              v-if="data.item.claim_data.some(item => ((item.claim_type === 'rts_tidak_diterima' || item.claim_type === 'rts_rusak') && data.item.is_retur === 2) || (item.claim_type === 'paksa_rts' && data.item.is_retur === 1))"
              :class="claimStatusClass(data.item.claim_data.find(item => ((item.claim_type === 'rts_tidak_diterima' || item.claim_type === 'rts_rusak') && data.item.is_retur === 2)|| (item.claim_type === 'paksa_rts' && data.item.is_retur === 1)).claim_status)"
              class="text-[12px] text-center"
            >
              {{ data.item.claim_data.some(item => item.claim_type === 'paksa_rts' && data.item.is_retur === 1) ? 'Laporan Retur Diterima' : data.item.claim_data.some(item => item.claim_type === 'rts_tidak_diterima') ? 'RTS Tidak Diterima' : 'RTS Rusak' }} {{ labelClaims(data.item.claim_data.find(item => ((item.claim_type === 'rts_tidak_diterima' || item.claim_type === 'rts_rusak') && data.item.is_retur === 2) ||( item.claim_type === 'paksa_rts' && data.item.is_retur === 1)).claim_status) }}
            </div>
            <b-popover
              :target="`status-claim-tooltip-${data.index}`"
              triggers="hover"
              placement="top"
            >
              <span style="color: #828282">
                {{ showLabelStatusClaim(data.item.claim_data.find(item => ((item.claim_type === 'rts_tidak_diterima' || item.claim_type === 'rts_rusak') && data.item.is_retur === 2)|| (item.claim_type === 'paksa_rts' && data.item.is_retur === 1))) }}
              </span>
            </b-popover>
          </div>
          <div
            v-if="problematicPackage.id === data.item.order_id && problematicPackage.isShow"
            class="wrapper-problematic-package py-50 px-1"
            @click="moveToHelper(data.item)"
          >
            <span class="ext-[14px]">
              Paket Bermasalah
            </span>
            <b-img
              id="problematic-package-icon"
              class="icon-problematic-package"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/message-question.svg"
            />
            <b-popover
              target="problematic-package-icon"
              triggers="hover"
              placement="top"
            >
              <span style="color: #828282">
                {{ showAlertProblematic(data.item) }}
              </span>
            </b-popover>
          </div>
          <div
            v-if="data.item.product.length > 1"
            class="text-end"
          >
            <b-button
              v-b-toggle="'collapse-'+data.item.order_id"
              class="buttonCollapse px-0 text-right relative"
              variant="none"
              size="sm"
            >
              <span class="when-open">Tutup <b-icon-chevron-up /></span>
              <span class="when-closed">{{ data.item.product.length - 1 }} Produk lainnya <b-icon-chevron-down /></span>
            </b-button>
          </div>
        </div>
      </template>
    </b-table>
    <div
      v-if="loadingTable"
      class="loading-data-order"
    >
      <lottie-animation
        path="animation/loading-data-order.json"
        :width="300"
        :height="300"
      />
    </div>

    <!-- Modal Force -->
    <ModalForce
      ref="modal-force"
      :order-id="orderId"
      status="retur"
    />
  </div>
</template>
<script>
import {
  BTable, BRow, BCol, BPagination, BFormInput, BIconSearch, BButton, BPopover, BCollapse, VBToggle, BIconChevronUp, BIconChevronDown, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import {
  firstDateOfMonth,
  lastDateOfMonth,
} from '@/store/helpers'
import 'vue-select/dist/vue-select.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import {
  DAY_MONTH_YEAR, TIME, DDMMYYYY, DASH_DATE,
} from '@/libs/formatDate'
import secureLs from '@/libs/secureLs'
import { IDR } from '@/libs/currency'
import { labelClaims, showLabelStatusClaim, claimStatusClass } from '@/libs/label-claims'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import ModalBundlingQuantity from '@/views/components/modal/ModalBundling.vue'
import ModalForce from './components/ForceModal.vue'
import banner from './components/Banner.vue'

export default {
  components: {
    BTable, BRow, BCol, LottieAnimation, BFormInput, BIconSearch, BButton, BPopover, vSelect, BCollapse, BIconChevronUp, BIconChevronDown, flatPickr, ModalBundlingQuantity, ModalForce, banner,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    filterItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profile: secureLs.getItem('userData'),
      items: [],
      fields: [
        {
          key: 'order_date', label: 'Tanggal Order', thClass: 'align-middle', tdClass: 'align-top',
        },
        {
          key: 'customer_name', label: 'Pelanggan', thClass: 'align-middle', tdClass: 'align-top',
        },
        {
          key: 'product', label: 'Produk', thClass: 'align-middle', tdClass: 'align-top',
        },
        {
          key: 'grand_total', label: 'Total Pembayaran', thClass: 'align-middle', tdClass: 'align-top',
        },
        {
          key: 'order_status', label: 'Status retur', thClass: 'align-middle text-center', tdClass: 'align-top text-center',
        },
        {
          key: 'retur_date', label: 'Tanggal Diterima', thClass: 'align-middle text-center', tdClass: 'align-top text-center',
        },
        {
          key: 'details', label: 'Rincian', thClass: 'align-middle', tdClass: 'align-top',
        },
      ],
      loadingTable: false,
      formSearch: null,
      paymentMethod: [],
      productList: this.filterItem.products,
      productFilter: null,
      customerName: null,
      startDate: null,
      endDate: null,
      addressId: null,
      addressList: this.filterItem.warehouses,
      mountTrackingRetur: { name: 'Bulan ini' },
      startDateMetric: firstDateOfMonth,
      endDateMetric: lastDateOfMonth,

      productName: '',

      percentageRetur: 0,
      returOnProcess: 0,
      totalOrder: 0,
      totalOrderRetur: 0,
      filterMetricLabel: 'Bulan ini',
      totalFilterDataOrder: 0,
      filterDateDataOrder: false,
      isFilterProduct: false,
      isFilterAddress: false,
      isFilterPayment: false,
      limit: 50,
      offset: 0,
      isLastOrder: false,

      isRetur: false,
      DAY_MONTH_YEAR,
      TIME,
      DDMMYYYY,
      DASH_DATE,
      IDR,
      labelClaims,
      showLabelStatusClaim,
      claimStatusClass,

      problematicPackage: {
        isShow: false,
        id: null,
      },
      isActive: false,
      orderId: 0,
    }
  },
  computed: {
    filterProducts() {
      return this.filterItem.products
    },
    filterWarehouses() {
      return this.filterItem.warehouses
    },
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('main').offsetHeight && !this.loadingTable) {
        this.getNextData()
      }
    }
  },
  mounted() {
    this.fetchData()
    this.metricRetur('Bulan ini')
  },
  methods: {
    async fetchData(search) {
      this.loadingTable = true
      this.offset = 0

      const params = {
        order_status: 'Retur',
        search,
        product_id: this.productName,
        payment_method: this.paymentMethod,
        start_date: this.startDate,
        end_date: this.endDate,
        is_retur: this.isRetur ? 1 : null,
        limit: this.limit,
        offset: this.offset,
      }

      if (this.addressId !== null) {
        if (this.addressId.is_kompack === 1) {
          Object.assign(params, { warehouse_id: this.addressId.id })
        } else {
          Object.assign(params, { partner_address_id: this.addressId.id })
        }
      }

      await this.$http_komship.get(`v3/order/${this.profile.partner_detail.id}`, { params })
        .then(res => {
          const { data } = res.data
          this.items = data
          this.offset = data.length
          this.loadingTable = false
          if (data.length < this.limit) {
            this.isLastOrder = true
          } else {
            this.isLastOrder = false
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          })
          this.loadingTable = false
        })
      this.getReturOnProcess()
    },
    getNextData() {
      if (!this.isLastOrder) {
        this.loadingTable = true

        const params = {
          order_status: 'Retur',
          customer_name: this.formSearch,
          product_id: this.productName,
          payment_method: this.paymentMethod,
          start_date: this.startDate,
          end_date: this.endDate,
          is_retur: this.isRetur ? 1 : null,
          limit: this.limit,
          offset: this.offset,
        }

        if (this.addressId !== null) {
          if (this.addressId.is_kompack === 1) {
            Object.assign(params, { warehouse_id: this.addressId.id })
          } else {
            Object.assign(params, { partner_address_id: this.addressId.id })
          }
        }

        this.$http_komship.get(`v3/order/${this.profile.partner_detail.id}`, { params })
          .then(result => {
            const { data } = result.data
            this.items.push(...data)
            this.offset += data.length
            this.loadingTable = false
            if (data.length < this.limit) {
              this.isLastOrder = true
            }
          })
          .catch(err => {
            this.loadingTable = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            })
          })
        this.getReturOnProcess()
      }
    },
    getReturOnProcess() {
      this.$http_komship.get(`/v1/order/retur-on-process/${this.profile.partner_detail.id}?start_date=${this.startDate === null ? '' : this.startDate}&end_date=${this.endDate === null ? '' : this.endDate}`)
        .then(response => {
          this.returOnProcess = response.data.data.retur_on_process
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          })
        })
    },
    resetFilter() {
      this.startDate = null
      this.endDate = null
      this.addressId = null
      this.customerName = null
      this.paymentMethod = null
      this.filterDateDataOrder = false
      this.isFilterProduct = false
      this.isFilterAddress = false
      this.isFilterPayment = false
      this.totalFilterDataOrder = 0
      return this.fetchData()
    },
    async setPage(totalPage) {
      this.perPage = totalPage
      this.fetchData()
    },
    metricRetur(data) {
      this.filterMetricLabel = data
      let start = null
      let end = null
      if (data === 'Bulan ini') {
        start = moment(firstDateOfMonth).format('YYYY-MM-DD')
        end = moment().format('YYYY-MM-DD')
      } else {
        const now = new Date()
        const prevMonthLastDate = new Date(now.getFullYear(), now.getMonth(), 0)
        const prevMonthFirstDate = new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1)
        start = moment(prevMonthFirstDate).format('YYYY-MM-DD')
        end = moment(prevMonthLastDate).format('YYYY-MM-DD')
      }
      this.$http_komship.get(`/v1/order/metric-retur/${this.profile.partner_detail.id}?start_date=${start}&end_date=${end}`)
        .then(response => {
          this.percentageRetur = response.data.data.percentage_retur
          this.totalOrder = response.data.data.total_order
          this.totalOrderRetur = response.data.data.total_order_retur
        })
    },
    setFilterDate() {
      if (this.startDate !== null) {
        if (this.endDate !== null && !this.filterDateDataOrder) {
          this.totalFilterDataOrder += 1
          this.filterDateDataOrder = true
        }
      }
      if (this.endDate === null) {
        if (this.startDate === null && this.filterDateDataOrder) {
          this.totalFilterDataOrder -= 1
          this.filterDateDataOrder = false
        }
      }
    },
    setFilterProduct() {
      if (this.productName !== null && !this.isFilterProduct) {
        this.isFilterProduct = true
        this.totalFilterDataOrder += 1
      }
      if (this.productName === null) {
        this.totalFilterDataOrder -= 1
        this.isFilterProduct = false
      }
    },
    setFilterAddress() {
      if (this.addressId !== null && !this.isFilterAddress) {
        this.isFilterAddress = true
        this.totalFilterDataOrder += 1
      }
      if (this.addressId === null) {
        this.isFilterAddress = false
        this.totalFilterDataOrder -= 1
      }
    },
    setFilterPayment() {
      if (this.paymentMethod !== null && !this.isFilterPayment) {
        this.isFilterPayment = true
        this.totalFilterDataOrder += 1
      }
      if (this.paymentMethod === null) {
        this.isFilterPayment = false
        this.totalFilterDataOrder -= 1
      }
    },
    fetchIsRetur() {
      this.isRetur = !this.isRetur
      this.fetchData()
    },
    maxClaim(data) {
      const claimPeriods = {
        IDEXPRESS: 2,
        JNE: 7,
        SICEPAT: 2,
        SAP: 2,
        'J&T': 2,
        NINJA: 7,
      }
      const days = claimPeriods[data.item.shipping]
      const today = new Date()
      const dateAfterDays = new Date(data.item.updated_at)
      dateAfterDays.setDate(dateAfterDays.getDate() + days)

      return dateAfterDays.getTime() < today.getTime()
    },
    toggleProblemPackage(data) {
      if (data.order_id === this.problematicPackage.id) {
        this.problematicPackage.isShow = !this.problematicPackage.isShow
      } else {
        this.problematicPackage.isShow = true
      }
      this.problematicPackage.id = data.order_id
    },
    showAlertProblematic(data) {
      let result
      const shippingTypes = {
        IDEXPRESS: '2x24',
        JNE: '7x24',
        SICEPAT: '2x24',
        SAP: '2x24',
        NINJA: '7x24',
        'J&T': '2x24',
      }
      const complaintTime = shippingTypes[data.shipping]

      if (complaintTime) {
        result = `Diterima pada ${this.DDMMYYYY(data.updated_at)} (${this.TIME(data.updated_at)}). Kamu dapat melaporkan kendala melalui button ini.
                (Maksimal Komplain Ekspedisi ${data.shipping} adalah ${complaintTime} jam setelah tanggal update)`
      }
      return result
    },
    moveToHelper(data) {
      this.$router.push({ name: 'data-order-trouble-helper', params: { id: data.order_id, data } })
    },
    forceDelivered(orderId) {
      this.orderId = orderId
      const params = {
        title: 'Formulir Pengajuan Claim',
        typeClaims: 'Force RTS',
        ticketId: null,
        orderId: this.orderId,
        typeParams: 'paksa_rts',
      }
      this.$router.push({ name: 'pengajuan-order', params })
    },
  },
}
</script>
<style>
.form-search {
  padding-left: 40px;
  height: 45px;
  border-radius: 12px;
}
.button-detail {
  font-size: 14px;
  color: #08A0F7!important;
}
.button-detail:hover {
  color: #c3c3c3!important;
}
.icon-info {
  width: 20px;
  height: 20px;
  margin-left: 3px;
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.buttonCollapse {
  margin-left: -50px;
  width:130px;
}
.btnPage {
  padding: 4px 7px;
  margin-right: 5px;
}
.image-product {
  object-fit: cover;
  object-position: center center;
  width: 50px!important;
  height: 50px!important;
}

.b-popover-dark .popover-body {
    background:#24292F;
    color: white;
}
.wrapper-problematic-package {
  border-radius: 8px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--grayscale-line, #E2E2E2);
  cursor: pointer;
  color: black;
}

.wrapper-problematic-package:hover {
  background: #FF6A3A;
  color: white;
}

.wrapper-problematic-package:hover .icon-problematic-package {
  content: url('https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/message-question.svg');
}

.wrapper-status-claim {
  border-radius: 5px;
  border: 1px solid var(--warning-main, #FBA63C);
  background: var(--warning-bg, #FFF2E2);
  color: var(--warning-main, #FBA63C);
}

.wrapper-status-rejected {
  border-radius: 5px;
  border: 1px solid var(--error-main, #E31A1A);
  background: var(--error-bg, #FFEDED);
  color: var(--error-main, var(--error-main, #E31A1A));
}

.wrapper-status-received {
  border-radius: 5px;
  border: 1px solid var(--success-main, #34A770);
  background: var(--success-bg, #DCF3EB);
  color: var(--success-main, var(--success-main, #34A770));
}

.custom-class-table-received .table > tbody > tr > td {
  min-width: 250px;
}
</style>
